import {TextProperty} from "./textProperty";

export class LayerProperty extends TextProperty {
    constructor() {
        super();
        this.type = ".LayerProperty";
        this.name = "layer";
        this.label = "Layer";
        this.defaultValue = "";
        this.isEditable = false;
    }
}